import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    // TODO fix eslint extension
    component: () => import('../views/Home.vue')
  },
  // {
  //   path: '/portfolio',
  //   name: 'Portfolio',
  //   // TODO fix eslint extension
  //   component: () => import('../views/Portfolio.vue')
  // },
  {
    path: '/project/:id',
    name: 'Project',
    // TODO fix eslint extension
    component: () => import('../views/Project.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
