
import { defineComponent } from 'vue'
import { WEBSITE_CONFIG } from '@/config'

export default defineComponent({
  setup() {
    const config = WEBSITE_CONFIG()

    return {
      type: config.type
    }
  }
})
