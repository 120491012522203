
import { defineComponent, watchEffect, ref } from 'vue'

export default defineComponent({
  name: 'Loader',
  props: {
    isLoading: {
      required: true,
      type: Boolean
    },
    over: {
      default: false,
      type: Boolean
    }
  },
  setup(props) {
    const isShow = ref(false)
    const width = ref<string | null>(null)

    let timeout: number | undefined = undefined

    watchEffect(() => {
      clearTimeout(timeout)
      if (props.isLoading) {
        width.value = null

        timeout = setTimeout(() => {
          width.value = '50%'
        }, 100)

        isShow.value = true
      } else {
        width.value = '100%'

        timeout = setTimeout(() => {
          isShow.value = false
          width.value = null
        }, 500)
      }
    })

    return {
      isShow,
      width
    }
  }
})
