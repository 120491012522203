import { createStore } from 'vuex'
import { fetchHome } from '@/api/fetchHome'
import { fetchProject } from '@/api/fetchProject'
import { fetchProjects, fetchTwoProjects } from '@/api/fetchProjects'
import { WEBSITE_CONFIG } from '@/config'

export default createStore({
  state: {
    home: null,
    project: null,
    projects: null,
    twoProjects: null,
    loading: true
  },
  mutations: {
    addHomeData(state, data) {
      state.home = data
    },
    addProjectData(state, data) {
      state.project = data
    },
    addProjectsData(state, data) {
      state.projects = data
    },
    addTwoProjectsData(state, data) {
      state.twoProjects = data
    },
    setLoading(state, loading) {
      state.loading = loading
    }
  },
  actions: {
    FETCH_INFO({ commit }) {
      commit('setLoading', true)
      fetchHome().then((res) => {
        commit('addHomeData', res)
      })
    },
    async FETCH_PROJECT({ commit }, project) {
      const config = WEBSITE_CONFIG()
      if (config.type === 'mainrix') {
        commit('setLoading', true)
        const data = await fetchProject(project)
        const two = await fetchTwoProjects(project)
        commit('addProjectData', data)
        commit('addTwoProjectsData', two)
        commit('setLoading', false)
      } else {
        commit('setLoading', false)
      }
    },
    async FETCH_PROJECTS({ commit }) {
      const config = WEBSITE_CONFIG()
      if (config.type === 'mainrix') {
        commit('setLoading', true)
        const res = await fetchProjects()
        commit('addProjectsData', res)
        commit('setLoading', false)
      } else {
        commit('setLoading', false)
      }
    }
  },
  modules: {}
})
