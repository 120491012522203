import { createApp as createVueApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'

const createApp = () => {
  const app = createVueApp(App)
  const metaManager = createMetaManager(false)

  app.use(store)
  app.use(router)
  app.use(metaManager)
  app.use(metaPlugin) // optional, only needed for OptionsAPI (see below)

  return {
    app,
    router,
    metaManager
  }
}

const { app } = createApp()
router.isReady().then(() => app.mount('#app'))
