<template>
  <header v-if="socials">
    <HeaderLogo />
    <nav>
      <ul class="header-links">
        <li v-if="isMainrix"><router-link class="a header__item" to="/">Портфолио</router-link></li>
        <li v-for="(item, key) in socials" :key="key">
          <a :href="item" target="_blank" class="a header__item">{{
            key.charAt(0).toUpperCase() + key.slice(1)
          }}</a>
        </li>
        <li v-if="phone && isMainrix">
          <a :href="`tel:${phone.plain}`" class="a header__item" target="_blank">
            {{ phone.formatted }}
          </a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import HeaderLogo from '@/components/HeaderLogo'
import { WEBSITE_CONFIG } from '@/config'

export default defineComponent({
  components: { HeaderLogo },
  setup() {
    const store = useStore()
    const config = WEBSITE_CONFIG()

    const socials = computed(() => {
      if (store.state.home) {
        const items = {}
        if (store.state.home.social_networks.telegram) {
          items.telegram = store.state.home.social_networks.telegram
        }
        if (store.state.home.social_networks.whatsapp) {
          items.whatsapp = store.state.home.social_networks.whatsapp
        }
        return items
      }
      return null
    })

    const phone = computed(() =>
      store.state.home &&
      store.state.home.phone &&
      store.state.home.phone.plain &&
      store.state.home.phone.formatted
        ? store.state.home.phone
        : null
    )

    return {
      socials,
      phone,
      isMainrix: config.type === 'mainrix'
    }
  }
})
</script>

<style scoped>
header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 36px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.header-links {
  display: flex;
  list-style: none;
}

.header__item {
  margin: 0 20px;
}

.header__item.router-link-active::before,
.header__item.router-link-active::after {
  opacity: 1;
}

.header__item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1024px) {
  header {
    padding: 24px 24px 0;
  }
}

@media screen and (max-width: 640px) {
  header {
    padding: 16px 16px 15px;
  }
}

@media screen and (max-width: 485px) {
  header {
    align-items: flex-start;
  }

  .header-links {
    display: block;
  }

  .header__item {
    margin: 0;
  }
}
</style>
