type ConfigItem = {
  type: 'mainrix' | 'rebase'
  title: 'Mainrix' | 'Rebase'
  url: string
  startYear: number
  city: 'Moscow' | 'Saint-Petersburg' | ''
  customLocale?: 'en' | 'ru'
}

type Config = {
  [key in 'rebase' | 'mainrix']: ConfigItem
}

const config: Config = {
  rebase: {
    title: 'Rebase',
    type: 'rebase',
    url: 'https://strapi.rebase.agency',
    startYear: 2020,
    city: '',
    customLocale: 'en'
  },
  mainrix: {
    title: 'Mainrix',
    type: 'mainrix',
    url: 'https://strapi.displaymain.com',
    startYear: 2017,
    city: 'Moscow'
  }
}

export const WEBSITE_CONFIG = (): ConfigItem =>
  process.env.VUE_APP_WEBSITE === 'rebase' ? config.rebase : config.mainrix
