import { Home } from '@/types/Home'
import axios from 'axios'
import { WEBSITE_CONFIG } from '@/config'
import { HomeResponse } from '@/types/HomeResponse'

export const defaultValues: Home = {
  social_networks: {
    telegram: '',
    instagram: '',
    whatsapp: ''
  },
  description: '',
  locale: 'ru-RU',
  localizations: [],
  offer: '',
  email: ''
}

const config = WEBSITE_CONFIG()

export const fetchHome = async (): Promise<Home> => {
  try {
    const { data } = await axios.get(
      `${config.url}/api/home-page${config.customLocale ? `?_locale=${config.customLocale}` : ''}`
    )
    const response = data as HomeResponse
    return response.data.attributes
  } catch (e) {
    console.error(e)
    return defaultValues
  }
}
