
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import { computed, defineComponent, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import 'normalize.css'
import Loader from '@/components/Loader.vue'
import { useMeta } from 'vue-meta'
import { WEBSITE_CONFIG } from '@/config'

export default defineComponent({
  name: 'App',
  components: {
    Loader,
    Header,
    Footer
  },
  setup() {
    const store = useStore()
    const home = computed(() => store.state.home)

    const config = WEBSITE_CONFIG()

    const metaConfig = computed(() => {
      const title = config.title
      if (home.value) {
        const description = home.value.offer
        return {
          title,
          description,
          og: {
            title,
            description,
            type: 'website',
            image: '/logo.svg'
          }
        }
      }
      return {}
    })

    const createLink = (rel: string, file: string, sizes?: string, type?: string) => {
      const link = document.createElement('link')
      link.rel = rel
      link.href = `/${config.type === 'rebase' ? 'rebase-favicons' : 'mainrix-favicons'}/${file}`
      if (sizes) {
        link.setAttribute('sizes', sizes)
      }
      if (type) {
        link.type = type
      }
      if (rel === 'mask-icon') {
        link.setAttribute('color', '#000000')
      }
      document.head.appendChild(link)
    }

    const createFavicons = () => {
      createLink('shortcut icon', 'favicon.ico')
      createLink('apple-touch-icon', 'apple-touch-icon.png', '180x180')
      createLink('icon', 'favicon-32x32.png', '32x32', 'image/png')
      createLink('icon', 'favicon-16x16.png', '16x16', 'image/png')
      createLink('manifest', 'site.webmanifest')
      createLink('mask-icon', 'safari-pinned-tab.svg')
    }

    useMeta(metaConfig)

    onBeforeMount(() => {
      store.dispatch('FETCH_INFO')
      createFavicons()
    })

    return {
      home,
      loading: computed(() => store.state.loading)
    }
  }
})
