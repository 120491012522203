import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Metainfo = _resolveComponent("Metainfo")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Metainfo),
    _createVNode(_component_Loader, {
      "is-loading": _ctx.loading,
      over: ""
    }, null, 8, ["is-loading"]),
    _createElementVNode("div", {
      style: _normalizeStyle({ opacity: !_ctx.loading ? 1 : 0 }),
      class: "app_main"
    }, [
      _createVNode(_component_Header),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_view),
        _createVNode(_component_Footer)
      ])
    ], 4)
  ], 64))
}