
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import FooterLogo from '@/components/FooterLogo.vue'
import { WEBSITE_CONFIG } from '@/config'

export default defineComponent({
  name: 'Footer',
  components: { FooterLogo },
  setup() {
    const store = useStore()
    const date = new Date()
    const config = WEBSITE_CONFIG()

    return {
      info: computed(() => store.state.home),
      year: date.getFullYear(),
      config
    }
  }
})
