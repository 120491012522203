import axios from 'axios'
import { Project } from '@/types/Project'
import { WEBSITE_CONFIG } from '@/config'
import { ProjectsResponse } from '@/types/ProjectsResponse'

export const LIMIT_PROJECTS = 5

const config = WEBSITE_CONFIG()

export const fetchProjects = async (page?: number): Promise<Project[]> => {
  const start = page ? page * LIMIT_PROJECTS : 0
  try {
    const { data } = await axios.get(
      `${config.url}/api/projects?pagination[start]=${start}&pagination[limit]=${LIMIT_PROJECTS}&populate[0]=categories&populate[1]=cover`
    )
    const response = data as ProjectsResponse
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}

export const fetchTwoProjects = async (id: ProjectId): Promise<Project[]> => {
  try {
    const { data } = await axios.get(
      `${config.url}/api/projects?filters[id][$ne]=${id}&pagination[limit]=2&populate[0]=categories&populate[1]=cover`
    )
    const response = data as ProjectsResponse
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}
