import { Project } from '@/types/Project'
import axios from 'axios'
import { WEBSITE_CONFIG } from '@/config'
import { ProjectResponse } from '@/types/ProjectResponse'

const config = WEBSITE_CONFIG()

export const fetchProject = async (id: string | string[]): Promise<Project | undefined> => {
  try {
    const { data } = await axios.get(
      `${config.url}/api/projects/${id}?populate[0]=categories&populate[1]=cover`
    )
    const response = data as ProjectResponse
    return response.data
  } catch (e) {
    console.error(e)
    return undefined
  }
}
